import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {ServiceService} from '../../services/service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../services/pager';
import { NgxSpinnerService } from "ngx-spinner";
import { PageEvent } from '@angular/material/paginator';
import { BaseService } from '../../services/base.service';



@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  form: any;
  error = '';
  register = false;
  tarjetas : any = [];
  items: Array<any> = [];
  IDcustomer: any;
  confirm: boolean = false;

  errorUpdate = '';

  pageOfItems: Array<any> = [];

  // PARA MANEJO DE TABLAS
  //@ViewChild(MatPaginator) paginator: MatPaginator ;
  //@ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['maskCreditCard', 'expMonthCreditCard', 'expMonth' ,'expYearCreditCard', 'nameHolderCreditCard', 'createdAt', 'estatus','actions'];
  dataSource: any;

  constructor(private baseService : BaseService,private spinner: NgxSpinnerService,private call : ServiceService,public  tableService: TableService<any>,private router: Router,
		private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.spinner.show();
    this.tableService.pager.pageSize = 10 ; 
    this.form = new FormGroup({
      numberCreditCard: new FormControl('', Validators.required),
      nameHolderCreditCard: new FormControl('', Validators.required),
      expMonthCreditCard: new FormControl('', [Validators.required, Validators.min(1), Validators.max(12), Validators.minLength(2)]),
      expYearCreditCard: new FormControl('', [Validators.required, Validators.min(1), Validators.max(99), Validators.minLength(2)]),
    });

      
      let data : any = localStorage.getItem('currentData');
      this.IDcustomer = JSON.parse(data).customer;
      this.call.findByCustomerInCorer(this.IDcustomer).subscribe(params =>{
        if(params['result'] != null){
          this.tarjetas = params['result'];
          //Por cada tarjeta
          for(let i in this.tarjetas){

            let tdc = this.tarjetas[i].creditCard;
            //Se verifica la integridad de la tdc chequeando si posee un token en HecBillingCustomer
            this.call.findByToken(this.IDcustomer,this.tarjetas[i].tokenCreditCard).subscribe(params => {
              //Si la tarjeta esta correctamente referenciada
              if(params['result'] != null) {
                switch(tdc.expMonthCreditCard){
                  case 1 : tdc.expMonth = "ENERO";
                  break;
                  case 2 : tdc.expMonth = "FEBRERO";
                  break;
                  case 3 : tdc.expMonth = "MARZO";
                  break;
                  case 4 : tdc.expMonth = "ABRIL";
                  break;
                  case 5 : tdc.expMonth = "MAYO";
                  break;
                  case 6 : tdc.expMonth = "JUNIO";
                  break;
                  case 7 : tdc.expMonth = "JULIO";
                  break;
                  case 8 : tdc.expMonth = "AGOSTO";
                  break;
                  case 9 : tdc.expMonth = "SEPTIEMBRE";
                  break;
                  case 10 : tdc.expMonth = "OCTUBRE";
                  break;
                  case 11: tdc.expMonth = "NOVIEMBRE";
                  break;
                  case 12 : tdc.expMonth = "DICIEMBRE";
                  break;
                }
                
                //Se carga el nombre del proveedor TDC en la columna indicada
                tdc.expMonthCreditCard = this.tarjetas[i].providerCreditCard.description;
                //Se corta el numero enmascarado de la TDC de manera de mostrar solo los ultimos 8 digitos
                tdc.maskCreditCard = tdc.maskCreditCard.substr(tdc.maskCreditCard.length-8);
                //Se muestra el año de vencimiento de la tarjeta en el formato YYYY
                tdc.expYearCreditCard = "20" + tdc.expYearCreditCard;
                //Se busca el estatus de la TDC de manera de mostrarlo al cliente
                this.call.findTdcById(this.IDcustomer,tdc.id).subscribe(parms => {
                  
                  //Se guarda la descripcion del estado en la columna correspondiente
                  tdc.estatus = parms['result']['statusCreditCard'] == 1 ? "Activo" : "Inactivo";
                  //Se anade la TDC a la tabla correspondiente
                  this.items.push(tdc);
                  this.dataSource = new MatTableDataSource<any>(this.items.slice(0,11));
                  this.tableService.pager.totalEntities = this.items.length ;
                  this.spinner.hide()

                }, (err) => console.log(err) );
              }

             
             

            }, (err) => {
              console.log("ERRORRR");
              console.log(err)
             } );
          }
          this.spinner.hide()
     
          
        }else{
          this.spinner.hide();
          console.log("NO TDC")
        } 


      }, err=>{
        this.spinner.hide();
      })
            /**
        x
       */
      

  }

  list(event?: PageEvent){

    let size = event ? event.pageSize : this.tableService.pager.pageSize
    let index = event ? event.pageIndex : this.tableService.pager.pageIndex;
    let itemsToShow;
    if(index == 0){
      itemsToShow = this.items.slice(0, size + 1)
    }else{
      itemsToShow = this.items.slice( (size*index), (size * (index * 2)) + 1)
    }
    
    this.tableService.pager.totalEntities = this.items.length -1;
    this.dataSource = new MatTableDataSource<any>(itemsToShow);
  }

  send(){
    this.clearMessages();
    this.spinner.show()
    let obj = this.form.value;
      //Se verifica el rango del mes y ano de vencimiento de la TDC
        if (this.verifyCCYearAndMonth(obj.expMonthCreditCard.toString(),obj.expYearCreditCard.toString())) {
          //Se verifica el numero de digitos de la TDC
          if (obj.numberCreditCard.toString().length >= 13 && obj.numberCreditCard.toString().length <= 19) {
            //Se verifica que el numero de la TDC cumpla con el algoritmo de Luhm
            //if (this.valid_credit_card(obj.numberCreditCard.toString())) {
              //En caso de que el numero de tarjeta (ultimos digitos) coincidan con una TDC previamente agregada, pedir confirmacion
                
                obj.estatus = "1";
                obj.numberCreditCard = obj.numberCreditCard.toString();

                // HACEMOS ESTO PARA VALIDAR QUE NO ESTE INGRESANDO UNA TARJETA YA REGISTRADA
                // let found = false;
                // for(let tdc of this.items){
                //   if(tdc.maskCreditCard.substr(tdc.maskCreditCard.length-4) == obj.numberCreditCard.toString().substr(obj.numberCreditCard.toString().length-4) ){
                //     found = true;
                //   }
                // }
                
              //  if(!found){
                  //Se crea la TDC con todos los valores introducidos
                  this.call.create(this.IDcustomer,obj).subscribe(params => {
                                  
                            
                    this.register = false;
                    this.items = [];
                    this.dataSource = [];
                    this.ngOnInit();

                    this.spinner.hide();

                  }, err => {

                  //En caso de error al no reconocer el proveedor de la TDC
                  if (err == "Credit card provider doesn't exist") {
                    this.error = "Proveedor de la tarjeta de crédito desconocido";
                    this.spinner.hide();
                  }else{
                    this.error = "No se pudo crear tarjeta";
                    this.spinner.hide();
                  }
                  }); 
              //  }else{
              //    this.error = "La tarjeta ya se encuentra registrada. "
              //    this.spinner.hide();
              //  }
                
              
           /* }
            else {
              this.error = "El número no corresponde a una tarjeta de crédito";
              this.show = false;
            }*/
          }
          else {
            this.error = "Verifique el número de la tarjeta. Cantidad de digitos inválida";
            this.spinner.hide();
          }
        }
        else {
          this.error = "Verifique el año/mes de vencimiento de la tarjeta introducida";
          this.spinner.hide();
        }
  }


  //Metodo que permite verificar el mes y ano de la TDC introducida
  verifyCCYearAndMonth(month : string, year: string) : boolean{
    //Verifica que el mes se encuentre entre 01 y 12
    if(1 < Number.parseInt(month) && Number.parseInt(month) > 12){
      return false;
    }

    //Verifica el limite inferior del ano de vencimiento
    if((Number.parseInt(month)-1) < new Date().getMonth()){
      if(Number.parseInt(year) <= Number.parseInt(new Date().getFullYear().toString().substr(2))){
        return false;
      }
    }
    else{
      if(Number.parseInt(year) < Number.parseInt(new Date().getFullYear().toString().substr(2))){
        return false;
      }
    }

    //Verifica el limite superior del ano de vencimiento
    if(Number.parseInt(year) >= (Number.parseInt(new Date().getFullYear().toString().substr(2))+15)){
        return false;
    }
    return true;
  }

  //Metodo que permite verificar si la TDC introducida cumple con el algoritmo de Luhm (es una tarjeta valida)
  valid_credit_card(value : any) {
    // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) return false;

    // The Luhn Algorithm. It's so pretty.
    var nCheck = 0, nDigit = 0, bEven = false;
    value = value.replace(/\D/g, "");

    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n),
        nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return (nCheck % 10) == 0;
  }


  //Permite solo numeros en el numero de la TDC
  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  goBack(){
    this.clearMessages();
    this.register = false;
  }

  clearMessages(){
    if(this.errorUpdate){
      this.errorUpdate = '';
    }
    if(this.error){
      this.error = '';
    }
  }

  add(){
    this.clearMessages();
    this.register = true;
  }

  delete(row:any){
    this.clearMessages();
  this.spinner.show()

    this.call.findTdcById(this.IDcustomer,row.id).subscribe(tdc =>{
    
      let json = {token : tdc.result.tokenCreditCard }
    
     this.call.deleteTdc(this.IDcustomer,tdc['result'].id,json).subscribe(response =>{
        this.items = [];
        this.dataSource = [];
          this.ngOnInit();
          this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        this.errorUpdate = "No se pudo cambiar el estado de la tarjeta, verifique que no se encuentre asociada a un plan activo"
      })
      
    }, err =>{
      console.log(err);
      this.error = err;
      this.spinner.hide();
    }) 
  }



}
