<app-welcome [showBrand]="true"></app-welcome>

<div class="content">
  <!-- Plan 1 -->
  <div class="plan">
    <img src="../../../assets/img/plan1.png" alt="" />
    <h3>{{ selectedExpresso?.title }}</h3>
    <hr />
    <p>{{ selectedExpresso?.description }}</p>

    <div class="select">
      <div></div>
      <div>
        <p class="pricet">
          <span>
            <h4>{{ selectedExpresso?.detail_plan }}</h4>
            {{ selectedExpresso?.frecuency.name_frecuency }}</span
          >
          ${{ selectedExpresso?.feeCost_plan }}
        </p>
        <!-- <img src="../../../assets/img/2bolsas.jpg" alt=""> -->
        <img src="../../../assets/img/expreso_master.jpg" alt=""  style="width: 100%;"  />
        <div class="radio">
          <form class="formtwo">
            <div>
              <p style="font-weight: 100; color: grey; font-size: 15px">
                Escoge un plan
              </p>
              <input
                (change)="expressoPlanSelected(selectedExpresso)"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectedExpresso"
                type="radio"
                [id]="plans[0]?.id"
                name="fav_language2"
                [value]="plans[0]"
              /><label for="ef"> {{ plans[0]?.name_plan }} </label><br />
              <input
                (change)="expressoPlanSelected(selectedExpresso)"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectedExpresso"
                type="radio"
                [id]="plans[1]?.id"
                name="fav_language2"
                [value]="plans[1]"
              /><label for="ef"> {{ plans[1]?.name_plan }} </label><br />
            </div>

            <div>
              <p style="font-weight: 100; color: grey; font-size: 15px">
                Escoge una opción
              </p>
              <span *ngFor="let option of optionsExpressoSeleted">
                <input
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="selected"
                  type="radio"
                  [id]="option.id"
                  name="fav_language"
                  [value]="option.id"
                /><label for="ef"> {{ option.name }} </label><br />
              </span>
            </div>

            <div class="button-submit">
              <input
                type="submit"
                (click)="goPlan(selectedExpresso?.id,true)"
                value="SELECCIONAR" />
            </div>
          </form>
        </div>
      </div>
      <div></div>
      <hr class="select" />
    </div>
  </div>

  <!--Plan 2-->
  <div class="plan">
    <img src="../../../assets/img/plan2.png" alt="" />
    <h3>{{ plans[2]?.title }}</h3>
    <hr />
    <p>{{ plans[2]?.description }}</p>
    <div class="select">
    <div></div>
    <div>
    <p class="pricet">
      <span>
        <h4>{{ plans[2]?.detail_plan }}</h4>
        {{ plans[2]?.frecuency.name_frecuency }}
      </span>
      ${{ plans[2]?.feeCost_plan }}
    </p>
    <img
      style="width: 100%;"
      src="../../../assets/img/plan2_prod.jpg"
      alt=""
    />
    <div class="radio">
      <form>
        <span *ngFor="let option of plans[2]?.options">
          <input
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="selected"
            type="radio"
            [id]="option.id"
            name="fav_language"
            [value]="option.id"
          /><label for="ef"> {{ option.name }} </label><br />
        </span>

        <div class="button-submit">
          <input
            type="submit"
            (click)="goPlan(plans[2]?.id,true)"
            value="SELECCIONAR"
          />
        </div>
      </form>
    </div>
    </div>
    </div>
  </div>
  <!-- Plan 3 nuevo Pedro editar-->

  <div class="plan">
    <img src="../../../assets/img/plan3.PNG" alt="" style="width: 25%" />
    <h3>{{ selectedPlan?.title }}</h3>
    <hr />
    <div class="select">
      <div></div>
      <div>
    <p class="pricet">
      <span>
        <h4>{{ selectedPlan?.detail_plan }}</h4>
        {{ selectedPlan?.frecuency.name_frecuency }}
      </span>
      ${{ selectedPlan?.feeCost_plan }}
    </p>
    <img src="../../../assets/img/capsula.PNG" alt="" style="width: 60%"  />
    <div class="radio">
      <form>
        <div>
          <p style="font-weight: 100; color: grey; font-size: 15px">
            Escoge un plan
          </p>
          <input
            [ngModelOptions]="{ standalone: true }"
            (change)="capsulePlanSelected(selectedPlan)"
            [(ngModel)]="selectedPlan"
            type="radio"
            [id]="plans[3]?.id"
            name="fav_language2"
            [value]="plans[3]"
          /><label for="ef"> {{ plans[3]?.name_plan }} </label><br />
          <input
            [ngModelOptions]="{ standalone: true }"
            (change)="capsulePlanSelected(selectedPlan)"
            [(ngModel)]="selectedPlan"
            type="radio"
            [id]="plans[4]?.id"
            name="fav_language2"
            [value]="plans[4]"
          /><label for="ef"> {{ plans[4]?.name_plan }} </label><br />
        </div>

        <!-- <div  > <p style="font-weight:100;color:grey;font-size:15px"> Escoge una opción </p> 

                <span *ngFor="let option of optionsSeleted" >
                  <input [ngModelOptions]="{standalone: true}" [(ngModel)]="selected" type="radio" [id]="option.id" name="fav_language" [value]="option.id" ><label for="ef"> {{ option.name }} </label><br>
                </span>

              </div> -->

        <div class="button-submit">
          <input
            type="submit"
            (click)="goPlan(selectedPlan?.id,false)"
            value="SELECCIONAR"
          />
        </div>
      </form>
    </div>
      </div>
    </div>
    
  </div>
</div>
