<header>
  <div class="logo" [routerLink]="'/'">
    <img src="../../../assets/img/logo.png" alt="">
  </div>
  <div class="menu">


    <nav>
          <div class="navbar">
            <div class="container nav-container">
              <input class="menu-btn" type="checkbox" id="menu-btn" />
              <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
              <!--  <div class="hamburger-lines">
                  <span class="line line1"></span>
                  <span class="line line2"></span>
                  <span class="line line3"></span>
                </div> -->


                <ul class="menu">
                <li *ngIf="!sesion" ><a style="cursor: pointer;" type="button" (click)="login()" >Iniciar sesión</a></li>


                <li *ngIf="!sesion" ><a style="cursor: pointer;" href="http://www.cafeunido.com/" target="_blank" >Ir a la tienda</a></li>
                </ul>
                <div *ngIf="sesion" class="option">

                  <a style="cursor: pointer;" type="button" mat-button [matMenuTriggerFor]="afterMenu">Mi cuenta <mat-icon class="option-icon">menu</mat-icon></a>
                  <mat-menu #afterMenu="matMenu" xPosition="after">
                    <button mat-menu-item (click)="profile()">Perfil</button>
                    <button mat-menu-item (click)="suscriptions()">Suscripciones</button>
                    <button mat-menu-item (click)="payments()">Medios de pago</button>
                    <button mat-menu-item (click)="addreses()">Tus direcciones</button>
                    <button mat-menu-item (click)="change()">Cambiar clave</button>
                    <button mat-menu-item (click)="goShop()"  >Ir a la tienda</button>
                    <button mat-menu-item (click)="logout()">Cerrar sesion</button>
                  </mat-menu>
                  
                </div>
                

            </div>
          </div>
        </nav>

  </div>
</header>


<!-- <header>
  <div class="container-center" [routerLink]="'/'">
    <img src="assets/images/logo.svg">
  </div>
  <div class="container-center text-dark text-font-semi-bold">

    <div *ngIf="!sesion" class="option"><a style="cursor: pointer;" type="button" (click)="login()" >Iniciar sesión</a></div>

    <div *ngIf="sesion" class="option">

      <a style="cursor: pointer;" type="button" mat-button [matMenuTriggerFor]="afterMenu">Mi cuenta <mat-icon class="option-icon">menu</mat-icon></a>
      <mat-menu #afterMenu="matMenu" xPosition="after">
        <button mat-menu-item (click)="profile()">Perfil</button>
        <button mat-menu-item (click)="suscriptions()">Suscripciones</button>
        <button mat-menu-item (click)="payments()">Medios de pago</button>
        <button mat-menu-item (click)="addreses()">Tus direcciones</button>
        <button mat-menu-item (click)="change()">Cambiar clave</button>
        <button mat-menu-item (click)="logout()">Cerrar sesion</button>
      </mat-menu>
    </div>

     

  </div>
</header> -->

 <!-- <div class="option"><a href="http://www.cafeunido.com/" target="_blank">Comprar  <mat-icon class="option-icon">shopping_cart</mat-icon> </a></div> -->


