import { Injectable } from "@angular/core";
import { HttpClient, HttpParams} from '@angular/common/http';
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { LocalStoreService } from "../local-store.service";
import { of } from 'rxjs';
import { findPhoneNumbersInText } from 'libphonenumber-js'



// ================= you will get those data from server =======

@Injectable({
  providedIn: "root",
})
export class JwtAuthService {

  

  token : any
  isAuthenticated: any;
  user: any = {};
  
  signingIn: any;
  return: any;
  JWT_TOKEN = "JWT_TOKEN";
  APP_USER = "EGRET_USER";

  private readonly BASE_URL: string = this.baseService.HOST + '/oauth';
	httpOptions : any;

  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private baseService : BaseService,
   
  ) {
    this.route.queryParams
      .subscribe(params => this.return = params['return'] || '/');
  }

  public signin(username : any, password : any, partner : any) {
    /*return of({token: DEMO_TOKEN, user: DEMO_USER})
      .pipe(
        delay(1000),
        map((res: any) => {
          this.setUserAndToken(res.token, res.user, !!res);
          this.signingIn = false;
          console.log(res);
          return res;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );*/

    // FOLLOWING CODE SENDS SIGNIN REQUEST TO SERVER

     this.signingIn = true;
     return this.login(username, password, partner)
       .pipe(
         map((res: any) => {
           return res;
         }),
         catchError((error) => {
           return throwError(error);
         })
       );
  }



  public signout() {
    this.setUserAndToken(null, null, false);
    localStorage.removeItem('currentData');
    this.router.navigateByUrl("sessions/signin");
  }

  isLoggedIn(): Boolean {
    return !!this.getJwtToken();
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
  getUser() {
    return this.ls.getItem(this.APP_USER);
  }

  setUserAndToken(token: any, user: any, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.user = user;
    //this.user$.next(user);
    this.ls.setItem(this.JWT_TOKEN, token);
    this.ls.setItem(this.APP_USER, user);
  }

  setToken(token: String) {
    this.token = token;
    this.ls.setItem(this.JWT_TOKEN, token);
  }

  login(username : string, password : string, partner : any): Observable<any> {
		let params = new HttpParams() ;
		params = params.set('grant_type', 'password');
		params = params.set('username', username);
		params = params.set('password', password);
		params = params.set('client_id', 'web_site');
    params = params.set('partner_id', partner);
		 // Http Headers
      this.httpOptions = {
			headers: this.baseService.createLoginHeader()
      }

		return this.http.post(this.BASE_URL + '/token',params,this.httpOptions)
		.pipe(map((res: any) => {

      sessionStorage.setItem('token', res.access_token);
      this.setToken(res.access_token);

      this.getUserById(res.user_id).subscribe(user => {
       
       if(user.user.status_user == 1){ // SOLO LO DEJAMOS ENTRAR SI ESTA ACTIVO
      // TODO - ESTO PUEDE NO SER NECESARIO

       let country = null;
       if(user.customer.phoneNumber_customer != undefined || user.customer.phoneNumber_customer != null){
        let findPhone = findPhoneNumbersInText(user.customer.phoneNumber_customer);
        if(findPhone.length > 0){
          country = findPhone[0].number.country;
        }
      }

       localStorage.setItem('currentData', JSON.stringify({user: user.user, token: res.access_token, customer:user.customer.id, country : country}));
       sessionStorage.setItem('user', user.user.id);
       //this.navigationService.menuOptions(); // cargamos las opciones que puede ver el usuario en el menu
       this.setUserAndToken(res.access_token, user.user, !!res);
       //this.notificationService.sucessLogin()
       this.signingIn = false;
       return res;	

       }else{
         //this.notificationService.error("Lo sentimos, su usuario se encuentra inactivo");
       }
       
     }, (err) =>{
       //this.notificationService.error(err);
       console.log(err);
     }); 

      
    }),catchError(this.handleError));
  }


  public handleError (error:any) {
    
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
    } else {
        // server-side error
        console.log(error.status);
        if (error.status === 400) {
          //   No se pudo actualizar, corriga los parámetros inválidos e intente nuevamente
          errorMessage = 'Email o password es incorrecto, intente nuevamente';
        }
        if (error.status === 401) {
          errorMessage = 'No se pudo actualizar, su contraseña actual es incorrecta';
        }
        if (error.status === 403) {
          //   No se pudo actualizar, corriga los parámetros inválidos e intente nuevamente
          errorMessage = 'No posee permisos suficientes para esta accion, contacte con el administrado';
        }
        if (error.status === 404) {
          errorMessage = 'No se pudo actualizar, registro inexistente';
        }
        if (error.status === 409) {
          errorMessage = 'No se pudo actualizar, corriga los parámetros requeridos o las dependencias inválidas e intente nuevamente';
        }
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  getUserById(id: number): Observable<any> {
   return this.http.get(this.baseService.HOST + '/user/ecommerce/' + id, this.httpOptions)
   .pipe(
     map(this.baseService.extractData),
     catchError(this.handleError)
     );
  }

  recoverPassforgot(request: {email: string}): Observable<any> {
		return this.http.post(this.baseService.HOST + '/user/recoverPassword', request)
			.pipe(catchError(this.handleError));
  }
  
  recoverPassReset(request: {pin: string, newPassword: string}): Observable<any> {
		return this.http.post(this.baseService.HOST + '/user/recoverPassword', request)
			.pipe(catchError(this.handleError));
	}
  

}
