<ngx-spinner></ngx-spinner>
<app-welcome></app-welcome>

<section  class="middle inset">

  <div *ngIf='!confirmation'>
    <h1 style="margin: 0px;" class="text-dark">
      Plan Seleccionado
    </h1>
  </div>
  <app-plan
  *ngIf='!confirmation'
    [class]="'checkout'"
    [description]="description"
    [image]="plan.url_image" 
    [title]="plan.title"
  ></app-plan>


  <div class="payment-form text-center y-center">
    <div class="steps container-center text-dark">
      <div class="point">
        <div>1</div>
      </div>
      <div class="step">
        <span>MÉTODO DE PAGO</span>
      </div>
      <div class="line"></div>
      <div class="point">
        <div>2</div>
      </div>
      <div class="step">
        <span>CONFIRMACION</span>
      </div>
    </div>

    <form *ngIf='!confirmation' [formGroup]="form" (ngSubmit)="checkout()">
      <div class="form">

        <div style="text-align: center;">
          <p class="text-dark">DATOS DE ENVIO</p>
        </div>

        <div  style="text-align: end;">
            <a *ngIf="userLogged" type="button" style="cursor: pointer;color:rgba(216,164,54,1);" (click)="addressesPopUp({})"> <u>  Seleccionar dirección </u> </a>
        </div>

        <input [formControl]="form.controls['url']" type="text" style="display: none;"/>

        <!-- NOMBRE - EMAIL -->
        <div class="row">
          <div  class="form-control">
            <input  [formControl]="form.controls['name']" type="text" placeholder="Nombre*"/>
            <small
            *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched" 
            class="form-error-msg"> Nombre es requerido </small>
          </div>
  
          <div  *ngIf="!userLogged" class="form-control">
            <input  [formControl]="form.controls['email']" type="text" placeholder="E-mail*"/>
            <small
            *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
            class="form-error-msg"> Email es requerido </small>

            <small 
            *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched" 
            class="form-error-msg"> Email Invalido. El formato debe ser example@dot.com </small>


          </div>

        </div>
        <div class="row">
          <div class="form-control">
            <mat-form-field style="width: 95%;">
              <mat-label>Provincia</mat-label>
              <mat-select (selectionChange)="onProvinceChange($event.value)" [formControl]="form.controls['province']"  >
                  <mat-option *ngFor="let p of provinces" [value]="p.id">{{p.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <small
                *ngIf="form.controls['province'].hasError('required') && form.controls['province'].touched" 
                class="form-error-msg"> Provincia es requerido </small>
          </div>

          <div class="form-control">
            <mat-form-field style="width: 95%;">
              <mat-label>Corregimiento</mat-label>
              <mat-select (selectionChange)="onCorregimientoChange($event.value)"  [formControl]="form.controls['corregimiento']"  >
                  <mat-option *ngFor="let c of corregimientos" [value]="c.id">{{c.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <small
                *ngIf="form.controls['corregimiento'].hasError('required') && form.controls['corregimiento'].touched" 
                class="form-error-msg"> Corregimiento es requerido </small>
          </div>

        </div>
  
         <!-- DIRECCION DEL BILLING O TDC -->
        <div class="row">
          <div  class="form-control">
            <input [formControl]="form.controls['adress']" type="text" placeholder="Dirección*"/>
            <small
            *ngIf="form.controls['adress'].hasError('required') && form.controls['adress'].touched" 
            class="form-error-msg"> Dirección es requerido </small>
          </div>
        </div>

        <div style="text-align: center;">
          <p class="text-dark">DATOS DE COBRO</p>
        </div>
  
        <div class="tdc-brands text-left">
          <img src="../../../assets/images/tdc-brands.png"/>
        </div>

        <div style="text-align: end;">
          <a  *ngIf="userLogged" type="button" style="cursor: pointer; color:rgba(216,164,54,1);" (click)="tdcPopUp({})" > <u> Seleccionar tarjeta </u> </a>
        </div>
  
         <!-- NOMBRE TITULAR Y NUMERO DE TARJETA -->
  
        <div class="row">
          <div  class="form-control">
            <input [formControl]="form.controls['name_tdc']" type="text" placeholder="Nombre del Titular*"/>
            <small
            *ngIf="form.controls['name_tdc'].hasError('required') && form.controls['name_tdc'].touched" 
            class="form-error-msg"> Nombre del Titular es requerido </small>
          </div>
          <div class="form-control">
            <input [formControl]="form.controls['number_tdc']" (change)="changeTDC()" (keypress)="onlyNumberKey($event)" type="text" placeholder="Número de Tarjeta*"/>
            <small
            *ngIf="form.controls['number_tdc'].hasError('required') && form.controls['number_tdc'].touched" 
            class="form-error-msg"> Número de Tarjeta es requerido </small>
          </div>
        </div>
  
         <!-- MES Y A*O DE EXPIRACION -->
        <div class="row">

          <div class="form-control">
            <mat-form-field style="width: 95%;">
              <mat-label>Mes de Expiración*</mat-label>
              <mat-select  [formControl]="form.controls['month']"  >
                  <mat-option value="01" >ENERO</mat-option>
                  <mat-option value="02" >FEBRERO</mat-option>
                  <mat-option value="03" >MARZO</mat-option>
                  <mat-option value="04" >ABRIL</mat-option>
                  <mat-option value="05" >MAYO</mat-option>
                  <mat-option value="06" >JUNIO</mat-option>
                  <mat-option value="07" >JULIO</mat-option>
                  <mat-option value="08" >AGOSTO</mat-option>
                  <mat-option value="09" >SEPTIEMBRE</mat-option>
                  <mat-option value="10" >OCTUBRE</mat-option>
                  <mat-option value="11" >NOVIEMBRE</mat-option>
                  <mat-option value="12" >DICIEMBRE</mat-option>
              </mat-select>
            </mat-form-field>
            <small
                *ngIf="form.controls['month'].hasError('required') && form.controls['month'].touched" 
                class="form-error-msg"> Mes de Expiración es requerido </small>
          </div>



          <div class="form-control">
            <mat-form-field style="width: 95%;">
              <mat-label>Año de Expiración*</mat-label>
              <mat-select  [formControl]="form.controls['year']"  >
                  <mat-option value="21">2021</mat-option>
                  <mat-option value="22">2022</mat-option>
                  <mat-option value="23">2023</mat-option>
                  <mat-option value="24">2024</mat-option>
                  <mat-option value="25">2025</mat-option>
                  <mat-option value="26">2026</mat-option>
                  <mat-option value="27">2027</mat-option>
                  <mat-option value="28">2028</mat-option>
                  <mat-option value="29">2029</mat-option>
                  <mat-option value="30">2030</mat-option>
                  <mat-option value="31">2031</mat-option>
                  <mat-option value="32">2032</mat-option>
                  <mat-option value="33">2033</mat-option>
                  <mat-option value="34">2034</mat-option>
                  <mat-option value="35">2035</mat-option>
                  <mat-option value="36">2036</mat-option>
                  <mat-option value="37">2037</mat-option>
                  <mat-option value="38">2038</mat-option>
                  <mat-option value="39">2039</mat-option>
                  <mat-option value="40">2040</mat-option>
              </mat-select>
            </mat-form-field>
            <small
                *ngIf="form.controls['year'].hasError('required') && form.controls['year'].touched" 
                class="form-error-msg"> Año de Expiración es requerido </small>
          </div>

          <div class="form-control">
            <input [formControl]="form.controls['ccv']" (keypress)="onlyNumberKey($event)" maxlength="3" type="text" placeholder="CCV*"/>

            <small
            *ngIf="form.controls['ccv'].hasError('required') && form.controls['ccv'].touched" 
            class="form-error-msg"> CVV es requerido </small>
            
          </div>
        </div>
  
      </div>


      
      <small 
      *ngIf="error" 
      class="form-error-msg"> ERROR !!! {{ error }}  por favor verifique datos</small>

      <small 
      *ngIf="info" 
      class="form-info-msg"> {{info}} <a *ngIf="!login" [href]="url"> AQUI </a> <a *ngIf="login" class="text-dark" type="button" style="cursor: pointer;" (click) ="goLogin()" > AQUI </a> </small>


      <small 
      *ngIf="develop" 
      class="form-info-msg"> {{develop}}  </small>


      <small 
              *ngIf="success" 
              class="form-success-msg"> Sus suscripción ha sido procesada exitosamente !!!</small>

      <div>
        <button class="btn" style="background: #967630; color: black;"   [disabled]="form.invalid" >Suscribirse</button>
        <br/>
        <a class="text-dark" type="button" style="cursor: pointer;"(click)="termsPopUp({})" >Términos & condiciones</a>
      </div>


    </form>

    <div *ngIf='confirmation' >
      <span  class="text-dark">Felicidades, su suscripción ha sido recibida con el número de confirmación</span><span style="font-style:normal;font-weight:bold;"> </span><span style="font-style:normal;font-weight:bold;color:rgba(216,164,54,1);">#{{id_hasplan}}.</span><br><span class="text-dark" ><br/>Recibirá un e-mail en su dirección </span><span style="font-style:normal;font-weight:bold;color:rgba(216,164,54,1);">{{customer_email}}</span><span class="text-dark"> con su confirmación.</span>
      
      <div>
        <a type="button" style="cursor: pointer;font-style:normal;font-weight:bold;color:rgba(216,164,54,1);" (click)="goSubs()" >Ver mis suscripciones</a>
      </div>

      <div class="form">

        <div class="row">

          <div class="form-control">
            <app-plan
            [class]="'checkout'"
            [image]="plan.url_image" 
              ></app-plan>
  
          </div>
  
          <div class="form-control ">
  
            <mat-form-field class="no-line"  style="width: 60%;height: 10%;">
              <input
              readonly
              style="color: black;"
              matInput
              name="name"
              placeholder="Plan:"
              [value]="plan.title">
          </mat-form-field>

          <mat-form-field class="no-line" style="width: 60%;height: 10%;">
            <input
            readonly
            style="color: black;"
            matInput
            name="name"
            placeholder="Frecuencia:"
            [value]="plan?.frecuency?.name_frecuency">
          </mat-form-field>

          <mat-form-field class="no-line" style="width: 60%;height: 10%;">
            <input
            readonly
            style="color: black;"
            matInput
            name="name"
            placeholder="Dirección:"
            [value]="form.value.adress">
          </mat-form-field>
          
          <mat-form-field class="no-line" style="width: 60%;height: 10%;">
            <input
            readonly
            style="color: black;"
            matInput
            name="name"
            placeholder="Tarjeta:"
            [value]="tdc">
          </mat-form-field>
  
          </div>
  
        </div>

      </div>

     
     
    </div>

    

    
  </div>

  <!-- style="width:600px;height:600px;border:0;" style="position: absolute;width:0;height:0;border:0;" 

  <iframe (load)="myLoadEvent()" style="width:600px;height:600px;border:0;"   *ngIf="responseForm" [srcdoc]="responseForm | safeHtml"></iframe> -->

 

  
  <div class="ending"></div>
</section>


