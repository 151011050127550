<ngx-spinner></ngx-spinner>
<section class="middle inset">
    <div class="payment-form text-center y-center">


        <div class="steps container-center text-dark">
            <div class="step">
              <span>Mis tarjetas</span>
            </div>
        </div>


        <form *ngIf="register"  [formGroup]="form" (ngSubmit)="send()">
            <div class="form">

                <div class="row">
                    <div class="form-control">
                      <input  [formControl]="form.controls['numberCreditCard']" type="text" (keypress)="onlyNumberKey($event)" placeholder="Número de tarjeta"/>
                      <small
                      *ngIf="form.controls['numberCreditCard'].hasError('required') && form.controls['numberCreditCard'].touched" 
                      class="form-error-msg"> Número es requerido </small>
                    </div>
                    <div class="form-control">
                      <input [formControl]="form.controls['nameHolderCreditCard']" type="text" placeholder="Nombre del titular"/>
                      <small
                      *ngIf="form.controls['nameHolderCreditCard'].hasError('required') && form.controls['nameHolderCreditCard'].touched" 
                      class="form-error-msg"> Nombre es requerido </small>
                    </div>

                    <div class="form-control">
                        <mat-form-field style="width: 95%;">
                          <mat-label>Mes de Expiración*</mat-label>
                          <mat-select  [formControl]="form.controls['expMonthCreditCard']"  >
                              <mat-option value="01" >ENERO</mat-option>
                              <mat-option value="02" >FEBRERO</mat-option>
                              <mat-option value="03" >MARZO</mat-option>
                              <mat-option value="04" >ABRIL</mat-option>
                              <mat-option value="05" >MAYO</mat-option>
                              <mat-option value="06" >JUNIO</mat-option>
                              <mat-option value="07" >JULIO</mat-option>
                              <mat-option value="08" >AGOSTO</mat-option>
                              <mat-option value="09" >SEPTIEMBRE</mat-option>
                              <mat-option value="10" >OCTUBRE</mat-option>
                              <mat-option value="11" >NOVIEMBRE</mat-option>
                              <mat-option value="12" >DICIEMBRE</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <small
                            *ngIf="form.controls['expMonthCreditCard'].hasError('required') && form.controls['expMonthCreditCard'].touched" 
                            class="form-error-msg"> Mes de Expiración es requerido </small>
                    </div>


                    <div class="form-control">
                        <mat-form-field style="width: 95%;">
                          <mat-label>Año de Expiración*</mat-label>
                          <mat-select  [formControl]="form.controls['expYearCreditCard']"  >
                              <mat-option value="21">2021</mat-option>
                              <mat-option value="22">2022</mat-option>
                              <mat-option value="23">2023</mat-option>
                              <mat-option value="24">2024</mat-option>
                              <mat-option value="25">2025</mat-option>
                              <mat-option value="26">2026</mat-option>
                              <mat-option value="27">2027</mat-option>
                              <mat-option value="28">2028</mat-option>
                              <mat-option value="29">2029</mat-option>
                              <mat-option value="30">2030</mat-option>
                              <mat-option value="31">2031</mat-option>
                              <mat-option value="32">2032</mat-option>
                              <mat-option value="33">2033</mat-option>
                              <mat-option value="34">2034</mat-option>
                              <mat-option value="35">2035</mat-option>
                              <mat-option value="36">2036</mat-option>
                              <mat-option value="37">2037</mat-option>
                              <mat-option value="38">2038</mat-option>
                              <mat-option value="39">2039</mat-option>
                              <mat-option value="40">2040</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <small
                            *ngIf="form.controls['expYearCreditCard'].hasError('required') && form.controls['expYearCreditCard'].touched" 
                            class="form-error-msg"> Año de Expiración es requerido </small>
                      </div>

                </div>

              </div>

              <small 
              *ngIf="error" 
              class="form-error-msg"> ERROR !!! {{ error }} , disculpe por favor verifique datos</small>


              <button style="margin-right: 20px; width: 14%;"  type="button" (click)="goBack()" >Cancelar</button>

              <button class="btn" [disabled]="form.invalid" >Registrar</button>
          
        </form>

        <div *ngIf="!register">
            <div class="m-333" fxLayout="row" style="text-align: end;">
                <!-- <span fxFlex></span> -->
                <button  mat-raised-button class="mb-8" color="primary" (click)="add()" >Agregar</button>
            </div>
    
    
            <div class="ml-8 mr-8 mt-8 pb-16">
                <div class="mat-elevation-z8">
                    <mat-table [dataSource]="dataSource" matSort >
    
                        <ng-container matColumnDef="maskCreditCard">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Número de tarjeta </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-label="maskCreditCard"> {{row.maskCreditCard}} </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="expMonthCreditCard">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor</mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="expMonthCreditCard"> {{row.expMonthCreditCard}} </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="expMonth">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Mes de vencimiento </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="expMonth"> {{row.expMonth}} </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="expYearCreditCard">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Año de vencimiento </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="expYearCreditCard"> {{row.expYearCreditCard}} </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="nameHolderCreditCard">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="nameHolderCreditCard"> {{row.nameHolderCreditCard}} </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="createdAt">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de creación </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="createdAt"> {{row.createdAt}} </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="estatus">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Estatus </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="estatus"> {{row.estatus}} </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="estatus"> 
                                <button style="background-color: white;" mat-icon-button [matMenuTriggerFor]="msgMenu" (click)="$event.stopPropagation()" class="hidden-on-open">
                                    <mat-icon class="text-muted">more_vert</mat-icon>
                                  </button>
                                <mat-menu style="width: 200px;" #msgMenu="matMenu">
                                    <button *ngIf="row.estatus=='Activo'" mat-menu-item (click)="delete(row)"> Eliminar  </button>
                                    <button *ngIf="row.estatus=='Inactivo'" mat-menu-item (click)="delete(row)"> Activar  </button>
                                </mat-menu>
                            </mat-cell>
                          </ng-container>
            
                    
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;" >
                        </mat-row>
                    </mat-table>

                    <mat-paginator showFirstLastButtons
                    [length]="tableService.pager.totalEntities"
                    [pageIndex]="tableService.pager.pageIndex"
                        [pageSize]="tableService.pager.pageSize"
                    [pageSizeOptions]="[10, 50, 100]"
                    (page)="list($event)">
                    </mat-paginator>  
                </div>
                
            </div>
        </div>
        

            
        

        

      
      <div>
      </div>
    </div>


    <small 
              *ngIf="errorUpdate" 
              class="form-error-msg"> ERROR !!! {{ errorUpdate }} </small>
  
    
    <div class="ending"></div>
  </section>
